// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

const FONT_LIGHT = 'DINPro-light'
const FONT_REGULAR = 'DINPro-regular';
const FONT_MEDIUM = 'DINPro-medium';
const FONT_BOLD = 'DINPro-bold';
const FONT_BLACK = 'DINPro-black';

// -----------------------------------------------------------------------------
// FONT DEFINITIONS
// -----------------------------------------------------------------------------


export default class Fonts {

  static header() {
    return {
      fontFamily: FONT_BOLD,
      fontSize: 17,
      letterSpacing: 2,

      backgroundImage:"url('/slash.svg')",
      backgroundRepeat:'no-repeat',
      backgroundPosition: 'left 3px',
      paddingLeft:'13px !important',
      backgroundSize:'10px 17px',
      textAlign: 'left',
      ['@media screen and (min-width: 768px)' ]: {
        fontSize: 20,
        backgroundPosition: 'left 3px',
        paddingLeft:'13px',
        backgroundSize:'10px 20px',
      },
    };
  }
  static headerold() {
    return {
      fontFamily: FONT_BOLD,
      fontSize: 17,
      letterSpacing: 2,
      ['@media screen and (min-width: 768px)' ]: {
        fontSize: 20,

      },
    };
  }
  static headerNewTitle() {
    return {
      fontFamily: FONT_BOLD,
      fontSize: 20,
      letterSpacing: 2,
      textShadow: '2px 2px #000',
      ['@media screen and (min-width: 768px)' ]: {
        fontSize: 30,
      },
    };
  }

  static topHeader() {
    return {
      fontFamily: FONT_MEDIUM,
      fontSize: 16,
      color: "#D2D2D2"
    };
  }

  static subHeader() {
    return {
      fontFamily: FONT_REGULAR,
      fontSize: 16,
      color: "#585858"
    };
  } 

  static title() {
    return {
      fontFamily: FONT_BOLD,
      fontSize: 16,
    };
  }

  static body() {
    return {
      fontFamily: FONT_MEDIUM,
      fontSize: 16,
    };
  }
  static bodySmall() {
    return {
      fontFamily: FONT_MEDIUM,
      fontSize: 12,
    };
  }

  static bodyBold() {
    return {
      fontFamily: FONT_MEDIUM,
      fontWeight: "bold",
      fontSize: 16,
    };
  }



  static light() {
    return {
      fontFamily: FONT_LIGHT,
    };
  }

  static regular() {
    return {
      fontFamily: FONT_REGULAR,
    }
  }

  static medium() {
    return {
      fontFamily: FONT_MEDIUM,
    }
  }

  static bold() {
    return {
      fontFamily: FONT_BOLD,
    };
  }

  static black() {
    return {
      fontFamily: FONT_BLACK,
    }
  }
};
