// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// React and React modules
import { withRouter } from "react-router";

// Container(s) for which we add styles within this module
import ScrollToTop from "./ScrollToTop";

export default withRouter(ScrollToTop)