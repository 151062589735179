// -----------------------------------------------------------------------------
// IMOPRTS
// -----------------------------------------------------------------------------

import colors from "./colors";
import fonts from "./fonts";
import variables from "./variables";
import mixins from "./mixins";

/**
 * Properties for the bridgestone theme
 *
 * @type {Object}
 * @module Theme:bridgestone
 */
export default {
  /**
   * Color definitions
   *
   * @type {Object}
   * @see Theme:bridgestone:colors
   */
  colors : colors,

  /**
   * Font definitions
   *
   * @type {Object}
   */
  fonts : fonts,

  /**
   * Variables
   *
   * @type {Object}
   */
  variables : variables,
  
  /**
   * Mixins
   *
   * @type {Object}
   */
  mixins : mixins
};
