
export default class Mixins {
    static noSelect() {
        return {
            userSelect: "none",/* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
            "-webkit-touch-callout": "none", /* iOS Safari */
            "-webkit-user-select": "none", /* Safari */
            "-khtml-user-select": "none", /* Konqueror HTML */
            "-moz-user-select": "none", /* Old versions of Firefox */
            "-ms-user-select": "none", /* Internet Explorer/Edge */
        };
    }
}
