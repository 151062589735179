// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// React and React modules
import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route, Switch, Redirect
} from 'react-router-dom';

// Other third party dependencies
// import postal from "postal";

// Internal dependencies
import ScrollToTop from './components/scrollToTop';

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// const CHANNEL_APP = postal.channel('app');

// -----------------------------------------------------------------------------
// VIEW CONFIGURATION
// -----------------------------------------------------------------------------

const CampaignHome = React.lazy(() => import("./screens/campaign-home"));
const CampaignDetails = React.lazy(() => import("./screens/campaign-details"));
const CampaignRegistration = React.lazy(() => import("./screens/campaign-registration"));
const ConventionRegistration = React.lazy(() => import("./screens/convention-registration"));
const ConventionReseller = React.lazy(() => import("./screens/convention-reseller"));
const ConventionRegistrationTest= React.lazy(() => import("./screens/convention-registration-test"));


// -----------------------------------------------------------------------------
// CLASS DEFINITION
// -----------------------------------------------------------------------------

/**
 * The base application component
 *
 * @extends React.Component
 */
class RootRouter extends React.Component {

    constructor(props) {
      super(props);
  
      // Initialize state
      this.state = {
        // drawerVisible: false,
        // shouldDrawerRender: true
      };
  
      // Bind event listener
      // this.onDrawerRequest = this.onDrawerRequest.bind(this);
  
      // this.subscriptions = [];
  
      //Define routes
      this.routes = [
        {
          path: '/promo',
          exact: true,
          name: "Home",
          render: props => {  
            // return (<Home {...props} renderDrawer={this.renderDrawer} ></Home>);
            return (<CampaignHome {...props}/>);
          }
        },
        // {
        //   path: '/italy',
        //   exact: true,
        //   name: "RedirectToPromo",
        //   render: props => {
        //     return (<Redirect to="/promo" />);
        //   }
        // },
        // {
        //   path: '/italy/:uid',
        //   exact: true,
        //   name: "CampaignDetails",
        //   render: props => {
        //     // return (<Home {...props} renderDrawer={this.renderDrawer} ></Home>);
        //     return (<CampaignDetails {...props} />);
        //   }
        // },
        {
          path: '/convenzioni',
          exact: true,
          name: "ConventionRegistration",
          render: props => {  
            return (<ConventionRegistration {...props}/>);
          }
        },
        {
          path: '/convenzioni/:uid',
          exact: true,
          name: "ConventionRegistration",
          render: props => {
            return (<ConventionRegistrationTest {...props}/>);
          }
        },
        {
          path: '/convenzioni/:uid/rivenditoriaderenti',
          exact: true,
          name: "ConventionReseller",
          render: props => {
            // return (<Home {...props} renderDrawer={this.renderDrawer} ></Home>);
            return (<ConventionReseller {...props} />);
          }
        },
        {
          path: '/promo/:uid',
          exact: true,
          name: "CampaignDetails",
          render: props => {
            // return (<Home {...props} renderDrawer={this.renderDrawer} ></Home>);
            return (<CampaignDetails {...props} />);
          }
        },
        {
          path: '/promo/:uid/registration',
          exact: true,
          name: "CampaignRegistration",
          render: props => {
            // return (<Home {...props} renderDrawer={this.renderDrawer} ></Home>);
            return (<CampaignRegistration {...props} />);
          }
        },
        {
          path: '/italy/voucherservizi2021',
          exact: true,
          render: props => {
            return (<Redirect to="/promo/voucherservizi2021" />);
          }
        },
        {
          path: '/italy/promoagri2021',
          exact: true,
          render: props => {
            return (<Redirect to="/promo/promoagri2021" />);
          }
        },
        {
          path: '/italy/promoduravis2021',
          exact: true,
          render: props => {
            return (<Redirect to="/promo/promoduravis2021" />);
          }
        },
        {
          path: '/offerte-pneumatici/:uid',
          exact: true,
          name: "CampaignDetails",
          render: props => {
            // return (<Home {...props} renderDrawer={this.renderDrawer} ></Home>);
            return (<CampaignDetails {...props} />);
          }
        },
        {
          path: '/offerte-pneumatici/:uid/registration',
          exact: true,
          name: "CampaignRegistration",
          render: props => {
            // return (<Home {...props} renderDrawer={this.renderDrawer} ></Home>);
            return (<CampaignRegistration {...props} />);
          }
        },
        {
          path: '/italy/:uid',
          exact: true,
          name: "CampaignDetails",
          render: props => {
            // return (<Home {...props} renderDrawer={this.renderDrawer} ></Home>);
            return (<CampaignDetails {...props} />);
          }
        },
        {
          path: '/italy/:uid/registration',
          exact: true,
          name: "CampaignRegistration",
          render: props => {
            // return (<Home {...props} renderDrawer={this.renderDrawer} ></Home>);
            return (<CampaignRegistration {...props} />);
          }
        },
        {
          path: '*',
          name: "Home",
          render: props => {
            return (<Redirect to="/promo" />);
          }
        }
      ]
    }
  
    // -----------------------------------------------------------------------------
    // EVENT HANDLER
    // -----------------------------------------------------------------------------
  
    // onDrawerRequest(visible = true) {
    //   this.setState({
    //     drawerVisible: visible,
    //     shouldDrawerRender: true
    //   });
    // }
  
    // renderDrawer = (shouldRender) => {
    //   this.setState({
    //     shouldDrawerRender: shouldRender
    //   });
    // }
  
    // ---------------------------------------------------------------------------
    // LIFECYCLE HOOKS
    // ---------------------------------------------------------------------------
  
    componentDidMount() {
    //   this.subscriptions.push(
    //     CHANNEL_APP.subscribe('app.drawer', this.onDrawerRequest)
    //   );
    }
  
  
    componentWillUnmount() {
    //   postal.unsubscribe.apply(postal, this.subscriptions);
    //   this.subscriptions.length = 0;
    }
  
    render() {
  
      return (
        <Router>
          <ScrollToTop>
            <React.Fragment>
              {/* {this.state.shouldDrawerRender
              && <Drawer
                visible={this.state.drawerVisible}
                routes={this.routes}
                login={() => { this.props.login({ login: true }) }}
                close={() => { this.onDrawerRequest(false); }}
              />} */}
              <Suspense fallback={<div></div>}>
                <Switch>

                  {this.routes.map((route) => {

                    return (<Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact || false}
                      render={route.render}
                    />)
                  }
                  )}

                </Switch>
              </Suspense>
            </React.Fragment>
          </ScrollToTop>
        </Router>

      );
    }
  }
  
  export default RootRouter;
