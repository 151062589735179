/**
 * Colors for the bridgestone theme
 *
 * @type {Object}
 * @module Theme:bridgestone:colors
 */
export default {
  primary:      "rgb(255, 255, 255)",
  primaryText:  "rgb( 76,  76,  76)",
  divider:      "rgba(0,0,0,0.2)",
  activeColor:  "#EE2029", //red
  gray:         "#F2F2F2",
  white:         "#FFF"
};
