// We need to define everthing here so that we can access individual variables
// for computations later on

// -----------------------------------------------------------------------------
// MEDIA QUERIES
// -----------------------------------------------------------------------------

const MQ_MIN_320 = "screen and (min-width: 320px)";
const MQ_MIN_480 = "screen and (min-width: 480px)";
const MQ_MIN_600 = "screen and (min-width: 600px)";
const MQ_MIN_768 = "screen and (min-width: 768px)";
const MQ_MIN_1280 = "screen and (min-width: 1280px)";

// -----------------------------------------------------------------------------
// COMMON
// -----------------------------------------------------------------------------

const BASIC_UNIT = 8;

// -----------------------------------------------------------------------------
// FONT STYLING
// -----------------------------------------------------------------------------

/**
 * Variables for the bridgestone theme
 *
 * @type {Object}
 * @module Theme:bridgestone:variables
 */
export default {
  // ---------------------------------------------------------------------------
  // MEDIA QUERIES
  // ---------------------------------------------------------------------------
  MQ_MIN_320: MQ_MIN_320,
  MQ_MIN_480: MQ_MIN_480,
  MQ_MIN_600: MQ_MIN_600,
  MQ_MIN_768: MQ_MIN_768,
  MQ_MIN_1280: MQ_MIN_1280,


  // ---------------------------------------------------------------------------
  // COMMON
  // ---------------------------------------------------------------------------

  /**
   * Basic unit for all calculation of any dimension. This is calculated in
   * relation to $font-size-default
   *
   * @see FONT_SIZE_DEFAULT
   *
   * @example
   *  $font-size-default: 10px // -> $basic-unit = 8px;
   *
   * @type {Number}
   * @default
   */
  basicUnit: BASIC_UNIT
};
