// React and React modules
import React from "react";

// Fela dependencies
import { createRenderer } from "fela";
import { RendererProvider as ReactFelaProvider, ThemeProvider } from "react-fela";
import unit from "fela-plugin-unit";
import prefixer from 'fela-plugin-prefixer';
import fallbackValue from 'fela-plugin-fallback-value';
import namedMediaQuery from "fela-plugin-named-media-query";
import monolithic from 'fela-monolithic';
import embedded from 'fela-plugin-embedded';

// Internal dependencies
import RootRouter from "./rootrouter";
import bridgestone from "./themes/bridgestone";


import { createMuiTheme, ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    // primary: {
    //   main: colors.red[500]
    // },
    // secondary: {
    //   main: colors.red[500]
    // },
  },
  typography: {
    fontFamily: [     
      'DINPro-regular', 
      'DINPro-black',
      'DINPro-medium',
      'DINPro-light',
      'DINPro-bold',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// Media queries for fela
const MEDIA_QUERY_MAP = {
  MQ_MIN_320:"@media (min-width: 320px)",
  MQ_MIN_480:"@media (min-width: 480px)",
  MQ_MIN_600:"@media (min-width: 600px)",
  MQ_MIN_768:"@media (min-width: 768px)",
  MQ_MIN_1280:"@media (min-width: 1280px)"
};


// Create a fela renderer for web. It uses the unit plugin to automatically
// add px as unit. The prefixer plugin is used to autoprefix CSS properties
// that are not supported by the user's browser.
const renderer = createRenderer({
  plugins: [
    embedded(),
    prefixer(),
    namedMediaQuery(MEDIA_QUERY_MAP),
    fallbackValue(),
    unit()
  ],
  enhancers: [
    monolithic({ prettySelectors: true })
  ]
});

function App() {
  return (
    <ReactFelaProvider renderer={renderer}>
      <ThemeProvider theme={bridgestone}>
        <MaterialThemeProvider theme={theme}>
          <RootRouter />
        </MaterialThemeProvider>
      </ThemeProvider>
    </ReactFelaProvider>
  );
}

export default App;
